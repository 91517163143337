.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

body {
    background-color: #282c34;
    margin: 0;
    padding: 0;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.fileInput {
    display: none;
}

.advancedLabel {
    font-size: 16px;
}

.advanced-settings-hidden {
    height: 50px;
    visibility: hidden;
}

.slider-hidden {
    height: 0px;
    visibility: hidden;
    overflow: hidden;
}

.slider-visible {
    height: 0px;
    visibility: visible;
}

.uploadFileButton, .advancedButton, .uploadButton {
    background-color: #61dafb;
    border: none;
    color: #282c34;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    width: 200px;
    margin-top: 20px;
    box-sizing: border-box;
    border: 1px solid transparent;
}

.uploadFileButton:hover, .advancedButton:hover, .uploadButton:hover {
    background-color: #282c34;
    color: #61dafb;
    border: 1px solid #61dafb;
}

.uploadButton[disabled] {
    background-color: #888;
    cursor: not-allowed;
}

.separator {
    height: 1px;
    background: #ffffff;
    margin: 30px -100px;
}